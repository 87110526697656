<template>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Detalle pago de CLientes</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3">Detalle Pagos</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12 border-2">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <span style="font-size: 1.5rem" class="text-900 mb-2"
        ><strong>DETALLE PAGO DE CLIENTES</strong> -
        {{ pago_cliente.tipo_pago_nombre ?? "" }}
      </span>
    </div>
    <div
      class="flex col-12"
      style="
        background-color: #f8f9fa;
        border-radius: 5px;
        border: 1px solid #ccc;
      "
    >
      <div class="col-6">
        <p class="mb-0">
          <strong>VENTA NRO.: </strong>
          <span style="font-weight: bold; font-size: 1.2rem">{{
            pago_cliente.venta_id
          }}</span>
        </p>
        <p class="mb-0">
          <strong>CLIENTE: </strong>
          <span>{{ pago_cliente.cliente_nombre ?? "" }}</span>
        </p>
        <p class="mb-0">
          <strong>TOTAL VENTA: </strong>
          <span style="font-weight: bold; font-size: 1.2rem">
            {{ convertirNumeroGermanicFormat(pago_cliente.total_venta) }}</span
          >
        </p>
      </div>
      <div class="col-6">
        <p class="mb-0">
          <strong>FECHA DE VENTA: </strong>
          <span>{{ pago_cliente.fecha_pago }}</span>
        </p>
        <p class="mb-0">
          <strong>TOTAL PAGADO: </strong>
          <span style="font-weight: bold; font-size: 1.2rem; color: green">{{
            total_pagado
          }}</span>
        </p>
        <p class="mb-0">
          <strong>SALDO: </strong>
          <span style="font-weight: bold; font-size: 1.2rem; color: red">{{
            convertirNumeroGermanicFormat(saldo ?? 0)
          }}</span>
        </p>
      </div>
    </div>
    <div class="col-12 mt-2">
      <DataTable
        ref="dt"
        :value="detalle_pagos"
        key="id"
        responsiveLayout="scroll"
        stripedRows
        showGridlines
      >
        <Column field="nombre_usuario" header="USUARIO" />
        <Column
          field="nro_cuota"
          header="NRO. CUOTA"
          style="text-align: center"
        />
        <Column field="fecha_pago" header="FECHA PAGO" />
        <Column field="observacion" header="OBSERVACIÓN" />
        <Column
          field="a_cuenta_efectivo"
          header="EFECTIVO"
          style="text-align: center"
        >
          <template #body="slotProps">
            <span>
              {{
                convertirNumeroGermanicFormat(slotProps.data.a_cuenta_efectivo)
              }}
            </span>
          </template>
        </Column>
        <Column
          field="a_cuenta_metodo_pago"
          header="TRANSFERENCIAS"
          style="text-align: center"
        >
          <template #body="slotProps">
            <span>
              {{
                convertirNumeroGermanicFormat(
                  slotProps.data.a_cuenta_metodo_pago
                )
              }}
              - {{ slotProps.data.nombre_metodo_pago }}
            </span>
          </template>
        </Column>
        <Column header="ACCIONES" style="text-align: center">
          <template #body="slotProps">
            <Button
              label="Imprimir"
              icon="pi pi-print"
              class="p-button-info p-button-rounded p-button-sm"
              @click="imprimirPago(slotProps.data)"
              :loading="imprimiendoPago"
            />
          </template>
        </Column>
      </DataTable>
    </div>
    <div class="flex col-12 justify-content-end">
      <Button @click="goBack" class="mr-2">
        <i class="pi pi-arrow-circle-left"></i>
        &nbsp;ATR&Aacute;S
      </Button>

      <Button
        label="Imprimir"
        icon="pi pi-file-pdf"
        class="p-button-danger p-button-lg"
        @click="imprimirDetalle()"
        :disabled="imprimiendo"
        :loading="imprimiendo"
      />
    </div>
  </div>
</template>
<script>
import PagoClienteService from "@/service/PagoClienteService.js";
export default {
  name: "PagosProveedorDetalle",
  data() {
    return {
      detalle_pagos: [],
      detalle_pago: {},
      pago_cliente: {},
      imprimiendo: false,
      imprimiendoPago: false,
    };
  },

  pagoClienteService: null,

  created() {
    this.pagoClienteService = new PagoClienteService();
  },
  computed: {
    total_pagado() {
      return this.sumarMontoTotal();
    },
    saldo() {
      let saldo = 0;
      saldo =
        parseFloat(this.pago_cliente.total_venta) -
        parseFloat(this.sumarMontoTotal());
      return saldo;
    },
  },
  mounted() {
    this.getDetallePagos();
    this.sumarMontoTotal();
  },

  methods: {
    imprimirPago(detalle) {
      let datos = {
        id: detalle.id,
        nombre_usuario: detalle.nombre_usuario,
        nro_cuota: detalle.nro_cuota,
        fecha_pago: detalle.fecha_pago,
        observacion: detalle.observacion,
        a_cuenta_efectivo: detalle.a_cuenta_efectivo,
        a_cuenta_metodo_pago: detalle.a_cuenta_metodo_pago,
        nombre_metodo_pago: detalle.nombre_metodo_pago,
        venta_id: this.pago_cliente.venta_id,
        sucursal_id: this.pago_cliente.venta.caja.sucursal_id || null,
        cliente_nombre: this.pago_cliente.cliente_nombre,
        total_venta: this.pago_cliente.total_venta,
        total_pagado: this.total_pagado,
        saldo: this.saldo,
      };
      this.imprimiendoPago = true;
      this.pagoClienteService
        .imprimirPago(datos)
        .then(() => {
          this.imprimiendoPago = false;
        })
        .catch(() => {
          this.imprimiendoPago = false;
        });
    },
    imprimirDetalle() {
      this.imprimiendo = true;
      this.pagoClienteService
        .imprimirDetalle(this.$route.params.id)
        .then(() => {
          this.imprimiendo = false;
        })
        .catch(() => {
          this.imprimiendo = false;
        });
    },
    sumarMontoTotal() {
      let a_cuenta_efectivo = 0;
      let a_cuenta_metodo_pago = 0;
      let total = 0;
      this.detalle_pagos.forEach((detalle) => {
        a_cuenta_efectivo += parseFloat(detalle.a_cuenta_efectivo);
        a_cuenta_metodo_pago += parseFloat(detalle.a_cuenta_metodo_pago);
      });
      total = a_cuenta_efectivo + a_cuenta_metodo_pago;
      return total;
    },
    goBack() {
      this.$router.go(-1);
    },
    getDetallePagos() {
      this.pagoClienteService
        .getDetallePagos(this.$route.params.id)
        .then((data) => {
          this.detalle_pagos = data.detalle_pagos;
          this.detalle_pago = data.detalle_pagos[0] || {};
          this.pago_cliente = data.pago_cliente;
        });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
  },
};
</script>
